@import "~@/style/variable.scss";
.contactus-container {
  max-width: 100%;
  background: #060030;

  .content {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      display: block;
    }
    .info-wrapper {
      .title {
        font-weight: 500;
        font-size: 26px;
        margin-bottom: 25px;

        @media (max-width: $screen-xxl) {
          font-size: 24px;
        }

        @media (max-width: $screen-lg) {
          font-size: 22px;
        }

        @media (max-width: $screen-md) {
          font-size: 22px;
        }
      }

      .en-title {
        font-weight: 600;
        font-size: 28px;
        font-family: BANKGOTHICCLTBT, sans-serif;
        color: #5d5a79;
        letter-spacing: 3px;
        margin-bottom: 12px;
      }

      p {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }


    .qrcode-container {
      display: flex;

      @media (max-width: $screen-md) {
        display: block;
      }

      .qrcode-wrapper {
        padding: 0 12px;
        text-align: center;

        img {
          max-width: 120px;
        }

        p {
          margin-top: 10px;
          font-size: 14px;
        }

        @media (max-width: $screen-md) {
          padding: 0 0;
          margin-top: 30px;
          text-align: left;

          img {
            max-width: 240px;
          }

          p {
            margin-top: 15px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
