@import "~@/style/variable.scss";
.main {
  background-image: linear-gradient(to bottom, #060030 70%, #360062 90%);
  & > div.container {
    //max-width: 90%;
    padding: 80px 30px;
    overflow: hidden;

    @media (max-width: $screen-md) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}
.content {
  & > h2.title {
    font-style: oblique;
    font-weight: 500;
    font-size: 50px;
    font-family: jinshanyunjishutiziti, sans-serif;
    color: #ffffff;

    @media (max-width: $screen-xxl) {
      font-size: 40px;
    }

    @media (max-width: $screen-lg) {
      font-size: 30px;
    }

    @media (max-width: $screen-md) {
      font-size: 20px;
      left: 3px;
    }

    &:before {
      content: attr(text);
      position: absolute;
      top: 1px;
      z-index: 10;
      background-image: linear-gradient(to top, transparent, #e3b9f8);
      -webkit-background-clip: text;
      color: transparent;
    }
  }

  & > h2.en-title {
    font-weight: 600;
    font-size: 44px;
    font-family: BANKGOTHICCLTBT, sans-serif;
    color: #6125a7;
    letter-spacing: 2px;

    @media (max-width: $screen-xxl) {
      font-size: 38px;
    }

    @media (max-width: $screen-lg) {
      font-size: 28px;
    }

    @media (max-width: $screen-md) {
      font-size: 18px;
    }
  }
}
