@import "~@/style/variable.scss";
.header-container {
  position: fixed;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  transition: box-shadow 0.5s;

  &.is-affix {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .header-wrapper {
    display: flex;
    width: calc(100% - 200px);
    max-width: 1200px;

    @media (max-width: $screen-xxl) {
      width: calc(100% - 100px);
    }

    @media (max-width: $screen-lg) {
      width: calc(100% - 40px);
    }
    .logo-wrapper {
      display: flex;
      align-items: center;
      margin-right: 25px;
      padding: 10px 0;
      img {
        width: auto;
        max-height: 80%;
        cursor: pointer;
      }
    }

    .menus {
      display: flex;
      color: #ffffff;

      li {
        padding: 0 27px;
        transition: all 0.5s;
        cursor: pointer;
        font-size: 18px;
        height: 80px;
        display: flex;
        position: relative;

        span {
          display: inherit;
          justify-content: center;
          align-items: center;

          svg {
            margin-left: 0.5em;
            transform: rotateZ(0);
            transition: all 0.5s;
          }
        }

        .dropdown-container {
          transform: scaleY(0);
          transition: all 0.5s;
          transform-origin: top;
          position: absolute;
          top: 100%;
          left: 0;
          background: #2f1559;
          width: 160px;
          min-width: 100%;
          color: #b718b7;

          .dropdown-item {
            padding: 15px 10px;
            cursor: pointer;
            transition: all 0.5s;
            text-align: center;

            &:hover {
              color: #ffffff;
              background: #b718b7;
            }
          }
        }



        &.active, &:hover {
          color: #9d61fd;
          background: rgba(#9d61fd, 0.1);
        }

         &:hover {
          color: #b718b7;
          background: rgba(#9d61fd, 0.1);

          .dropdown-container {
            transform: scaleY(1);
          }

          svg {
            transform: rotateZ(180deg);
          }
        }

        @media (max-width: $screen-xxl) {
          padding: 0 13px;
          font-size: 16px;
          height: 70px;
        }

        @media (max-width: $screen-lg) {
          padding: 0 8px;
          font-size: 14px;
          height: 60px;
        }
      }
    }
    //
    //@media (max-width: @screen-xxl-min) {
    //  width: calc(100% - 100px);
    //}
    //
    //@media (max-width: @screen-xl-min) {
    //  width: calc(100% - 60px);
    //
    //  .ant-menu-horizontal {
    //    .ant-menu-item {
    //      padding-right: 24px;
    //      padding-left: 24px;
    //      font-size: 16px;
    //
    //      & > span {
    //        transition: color 0.3s;
    //      }
    //    }
    //  }
    //}
    //
    //@media (max-width: @screen-lg-min) {
    //  width: calc(100% - 60px);
    //
    //  .ant-menu-horizontal {
    //    .ant-menu-item {
    //      padding-right: 18px;
    //      padding-left: 18px;
    //    }
    //  }
    //}
  }

  //&.isMobile {
  //  .headerWrapper {
  //    align-items: center;
  //    height: 70px;
  //  }
  //
  //  .mobile-menu {
  //    position: absolute;
  //    right: 20px;
  //    z-index: 999999;
  //    width: 16px;
  //    height: 14px;
  //    cursor: pointer;
  //    transition: all 0.3s;
  //
  //    em {
  //      display: block;
  //      width: 100%;
  //      height: 2px;
  //      margin-top: 4px;
  //      background: #666;
  //      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  //    }
  //
  //    :first-child {
  //      margin-top: 0;
  //    }
  //  }
  //
  //  .logoWrapper {
  //    position: absolute;
  //    left: 20px;
  //  }
  //}
  //
  //&.mobileOpen {
  //  background-color: #000;
  //
  //  .mobileMenu {
  //    em {
  //      &:nth-child(1) {
  //        transform: translateY(6px) rotate(45deg);
  //      }
  //
  //      &:nth-child(2) {
  //        opacity: 0;
  //      }
  //
  //      &:nth-child(3) {
  //        transform: translateY(-6px) rotate(-45deg);
  //      }
  //    }
  //  }
  //}
}
//
//.menu-drawer {
//  z-index: 99;
//
//  .ant-drawer-content {
//    background-color: #000;
//
//    .ant-drawer-body {
//      padding-top: 80px;
//      overflow-x: hidden;
//
//      .ant-menu {
//        color: #fff;
//        background-color: transparent;
//        border: 0;
//
//        .ant-menu-item {
//          &:active {
//            background-color: rgb(99 14 78 / 22%);
//          }
//        }
//      }
//    }
//
//    .ant-drawer-header {
//      display: none;
//      background-color: #000;
//    }
//  }
//}
//
