@import "~@/style/variable.scss";
.introduce-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;

  .content {
    background-image: url("~@/assets/img/home/introduce/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 8% 6%;
    position: relative;
    box-sizing: border-box;
    min-height: 1037px;

    @media (max-width: $screen-xl) {
      min-height: 875px;
    }
    @media (max-width: $screen-lg) {
      min-height: 730px;
    }
    @media (max-width: $screen-md) {
      background-image: url("~@/assets/img/home/introduce/m-bg.png");
      min-height: 702px;
      padding: 13% 6% 7%;
    }

    .title {
      position: absolute;
      top: -10px;
    }

    .en-title {
      position: absolute;
      top: 2%;
      right: 6%;
      letter-spacing: 12px;
      @media (max-width: $screen-xl) {
        letter-spacing: 10px;
      }

      @media (max-width: $screen-lg) {
        letter-spacing: 8px;
      }

      @media (max-width: $screen-md) {
        font-size: 18px;
        letter-spacing: 2px;
      }
    }

    .description {
      font-size: 20px;
      line-height: 38px;
      margin-bottom: 4%;

      @media (max-width: $screen-xl) {
        font-size: 18px;
        line-height: 35px;
      }

      @media (max-width: $screen-lg) {
        font-size: 16px;
        line-height: 32px;
      }
    }

    .media {
      background-image: url("~@/assets/img/home/introduce/video-border.png");
      position: relative;
      background-size: 100% 100%;
      padding: 20px;

      img, video {
        max-width: 100%;
      }

      .mask {
        position: absolute;
        top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        //background: rgba(#360062, 0.5);
        cursor: pointer;

        &:hover {
          .play {
            transform: scale(1.5);
          }
        }
        .play {
          width: 50px;
          height: 50px;
          background: #9d5ffe;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          opacity: 0.5;

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: {
              width: 10px;
              style: dashed;
              top-color: transparent;
              right-color: transparent;
              bottom-color: transparent;
              left-color: #ffffff;
            };
            margin-right: -15px;
          }
        }
      }
    }
  }
}
