@import "~@/style/variable.scss";
.playback-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;

  .content {
    background-image: url("~@/assets/img/home/playback/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 11% 8% 7%;
    position: relative;

    .title {
      position: absolute;
      top: -10px;
    }

    .en-title {
      position: absolute;
      top: 3%;
      right: 6%;
      letter-spacing: 4px;
      @media (max-width: $screen-xxl) {
        letter-spacing: 2px;
      }

      @media (max-width: $screen-lg) {
        letter-spacing: 1px;
      }

      @media (max-width: $screen-md) {
        letter-spacing: 0;
      }
    }


    .media-container {
      display: flex;

      .media {
        flex: 1;
        position: relative;

        img, video {
          max-width: 100%;
        }

        .mask {
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          //background: rgba(#360062, 0.5);
          cursor: pointer;

          &:hover {
            .play {
              transform: scale(1.5);
            }
          }
          .play {
            width: 50px;
            height: 50px;
            background: #9d5ffe;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
            opacity: 0.5;

            &:before {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border: {
                width: 10px;
                style: dashed;
                top-color: transparent;
                right-color: transparent;
                bottom-color: transparent;
                left-color: #ffffff;
              };
              margin-right: -15px;
            }
          }
        }
      }

      .menu-wrapper {
        position: relative;
        width: 280px;

        .menu {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          overflow-y: auto;
          max-height: 100%;
          background: rgba(0,0,0,0.5);
          padding: 20px 0;
          box-sizing: border-box;

          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: rgb(0,0,0,0.2);
            border-radius: 6px;
          }
          &::-webkit-scrollbar-thumb {
            background: #7c37e4;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #2a0366;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:active {
            background: #2a0366;
            border-radius: 10px;
          }

          li {
            padding: 15px 20px;
            cursor: pointer;
            transition: all 0.5s;
            line-height: 1.2;

            &.active, &:hover {
              background: rgb(111 57 234);
            }

            h4 {
              font-size: 15px;
              font-weight: 500;
              margin-bottom: 10px;
            }

            p {
              font-size: 15px;
              font-weight: 500;
              color: rgb(#fff, 0.8);
            }
          }
        }

        @media (max-width: $screen-xxl) {
        }

        @media (max-width: $screen-lg) {
          width: 230px;

          .menu {
            li {
              padding: 8px 15px;
              h4 {
                font-size: 13px;
                margin-bottom: 8px;
              }

              p {
                font-size: 13px;
              }
            }
          }
        }

        @media (max-width: $screen-md) {
          width: 140px;

          .menu {
            li {
              padding: 5px 10px;
              h4 {
                font-size: 13px;
                margin-bottom: 8px;
              }

              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
