@import "~@/style/variable.scss";
.guest-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;

  .content {
    background-image: url("~@/assets/img/home/guest/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 8% 5%;
    position: relative;
    min-height: 2400px;
    box-sizing: border-box;

    @media (max-width: $screen-md) {
      background-image: url("~@/assets/img/home/guest/m-bg.png");
    }

    @media (max-width: $screen-xxl) {
      min-height: 2292px;
    }
    @media (max-width: $screen-xl) {
      min-height: 1910px;
    }

    @media (max-width: $screen-lg) {
      min-height: 1510px;
    }

    @media (max-width: $screen-md) {
      min-height: 1536px;
    }

    .title {
      position: absolute;
      top: 10px;
      @media (max-width: $screen-md) {
        top: 0;
      }
    }

    .en-title {
      position: absolute;
      top: 2%;
      right: 6%;

      @media (max-width: $screen-xl) {
        font-size: 38px;
      }

      @media (max-width: $screen-lg) {
        font-size: 28px;
      }

      @media (max-width: $screen-md) {
        font-size: 12px;
        letter-spacing: 1px;
        top: 1%;
      }
    }

    .list {
      margin-top: 40px;
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;
      row-gap: 40px;

      @media (max-width: $screen-md) {
        grid-template-columns: 50% 50%;
      }
      .img-wrapper {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
