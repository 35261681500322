@import "~@/style/variable.scss";

.support-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;

  .content {
    background-image: url("~@/assets/img/home/support/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 8% 6%;
    position: relative;
    min-height: 870px;
    box-sizing: border-box;

    @media (max-width: $screen-xl) {
      padding: 10% 6%;
    }

    @media (max-width: $screen-lg) {
      padding: 14% 6%;
    }

    @media (max-width: $screen-md) {
      padding: 10% 6%;
      background-image: url("~@/assets/img/home/support/m-bg.png");
    }

    .title {
      position: absolute;
      top: -12px;

      @media (max-width: $screen-md) {
        top: -4px;
      }
    }

    .en-title {
      position: absolute;
      top: 3%;
      right: 6%;
      font-size: 30px;

      @media (max-width: $screen-xl) {
        font-size: 20px;
      }

      @media (max-width: $screen-lg) {
        font-size: 18px;
        letter-spacing: 1px;
      }

      @media (max-width: $screen-md) {
        font-size: 12px;
        top: 13px;
        letter-spacing: 0;
      }
    }

    .item {
      margin-bottom: 20px;
      display: flex;
      font-size: 24px;
      line-height: 1.3;

      .label {
        width: 120px;
        font-weight: 600;
      }
      .wrapper {
        flex: 1;
      }

      @media (max-width: $screen-xl) {
        font-size: 22px;
        .label {
          font-weight: 500;
          width: 120px;
        }
      }

      @media (max-width: $screen-lg) {
        font-size: 18px;

        .label {
          font-weight: 500;
          width: 110px;
        }
      }

      @media (max-width: $screen-md) {
        font-size: 16px;
        .label {
          font-weight: 500;
          width: 100px;
        }
      }
    }
    .list {
      margin-top: 25px;
      display: grid;
      grid-template-columns: repeat(6, 15.88%);
      column-gap: 10px;
      row-gap: 20px;

      li {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;

        img {
          max-width: 80%;
          max-height: 80%;
        }
      }

      @media (max-width: $screen-xl) {
        grid-template-columns: repeat(5, 19.11%);

        li {
          height: 70px;
        }
      }

      @media (max-width: $screen-lg) {
        grid-template-columns: repeat(4, 23.9%);

        li {
          height: 65px;
        }
      }

      @media (max-width: $screen-md) {
        grid-template-columns: repeat(2, 49%);

        li {
          height: 70px;
        }
      }
    }
  }
}
