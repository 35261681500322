@import "~@/style/variable.scss";
.videos-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
  min-height: 1217px;
  box-sizing: border-box;
  @media (max-width: $screen-xxl) {
    min-height: auto;
  }
  @media (max-width: $screen-md) {
    min-height: 400px;
  }

  .content {
    background-image: url("~@/assets/img/home/videos/bg.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 11% 8% 7%;
    position: relative;

    .title {
      position: absolute;
      top: -10px;
    }

    .en-title {
      position: absolute;
      top: 3%;
      right: 6%;
      letter-spacing: 12px;
      @media (max-width: $screen-xxl) {
        letter-spacing: 10px;
      }

      @media (max-width: $screen-lg) {
        letter-spacing: 8px;
      }

      @media (max-width: $screen-md) {
        letter-spacing: 3px;
      }
    }

    .media {
      position: relative;

      img, video {
        max-width: 100%;
      }

      .mask {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        //background: rgba(#360062, 0.5);
        cursor: pointer;

        &:hover {
          .play {
            transform: scale(1.5);
          }
        }
        .play {
          width: 50px;
          height: 50px;
          background: #9d5ffe;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5s;
          opacity: 0.5;

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: {
              width: 10px;
              style: dashed;
              top-color: transparent;
              right-color: transparent;
              bottom-color: transparent;
              left-color: #ffffff;
            };
            margin-right: -15px;
          }
        }
      }
    }
  }

  .swiper-container {
    max-width: 90%;
    margin: 6% auto 30px;
    position: relative;

    .swiper-slide {
      display: flex;
      justify-content: center;

      .slide-wrapper {
        position: relative;
        cursor: pointer;
        max-width: 100%;

        &:hover {
          .play {
            transform: scale(1.5);
          }
        }

        .mask {
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          //background: rgba(#360062, 0.5);
          background-image: linear-gradient(to bottom, transparent, #000000);

          .play {
            width: 30px;
            height: 30px;
            background: #9d5ffe;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
            opacity: 0.5;

            &:before {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border: {
                width: 6px;
                style: dashed;
                top-color: transparent;
                right-color: transparent;
                bottom-color: transparent;
                left-color: #ffffff;
              };
              margin-right: -8px;
            }
          }
        }

        .info {
          position: absolute;
          bottom: 0;
          padding: 15px 22px;

          h4 {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 10px;
          }

          p {
            line-height: 1.2;
            font-size: 16px;
          }

          @media (max-width: $screen-xl) {
            padding: 12px 15px;
            h4 {
              font-size: 16px;
              margin-bottom: 10px;
            }
            p {
              font-size: 14px;
            }
          }

          @media (max-width: $screen-lg) {
            padding: 10px 10px;
            h4 {
              font-size: 15px;
              margin-bottom: 8px;
            }
            p {
              font-size: 13px;
            }
          }

          @media (max-width: $screen-md) {
            padding: 10px 10px;
            h4 {
              font-size: 14px;
              margin-bottom: 8px;
            }
            p {
              font-size: 12px;
            }
          }
        }

        img {
          width: 100%;
          max-height: 182px;
        }
      }
    }

    .page-container {
      position: absolute;
      left: -6%;
      right: -6%;
      top: 50%;
      z-index: 1;
      height: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        display: inline-block;
        cursor: pointer;
        transform: rotateY(40deg);
        transition: all 0.5s;

        &.prev {
          img {
            transform: rotateZ(180deg);
          }
        }

        &:hover {
          transform: rotateY(0deg);
        }
      }
    }
  }
}
