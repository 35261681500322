@import "~@/style/variable.scss";
.banner-container {
  position: relative;
  background-image: url("~@/assets/img/home/banner/banner-bg.png");
  background-size: auto 100%;
  background-position: center ;
  overflow: hidden;
  min-height: 963px;
  @media (max-width: $screen-md) {
    min-height: 600px;
  }
  .content {
    width: 90%;
    font-family: jinshanyunjishutiziti, sans-serif;
    text-align: center;
    margin: 0 auto;
    padding: 240px 0 68px;
    font-size: 20px;
    position: relative;
    z-index: 1;

    h1, h2, h3 {
      font-weight: 500;
      font-family: jinshanyunjishutiziti, sans-serif;
    }

    .theme {
      margin-bottom: 50px;

      img {
        max-width: 60%;
        max-height: 64px;
      }
    }

    .title {
      margin-bottom: 60px;

      img {
        max-height: 130px;
        max-width: 100%;
      }
    }

    .time {
      margin-bottom: 153px;

      img {
        max-width: 100%;
        max-height: 36px;
      }
    }

    .logo {
      margin-bottom: 17px;

      img {
        max-width: 100%;
        max-height: 90px;
      }
    }

    .sponsor {
      img {
        max-width: 100%;
        max-height: 35px;
      }
    }

    @media (max-width: $screen-xl) {
    }

    @media (max-width: $screen-lg) {
    }

    @media (max-width: $screen-md) {
      padding: 180px 0 70px;

      .time {
        margin-bottom: 94px;
      }
    }
  }
}
